<template>
    <div>
        <b-modal id="employeeEdit" title="Изменить данные должности" size="sm" :no-close-on-backdrop="true" hide-footer no-enforce-focus @show="opened">
            <b-row>
                <b-col cols="6">
                    <b-form-group>
                        <label>Подразделение</label>
                       <v-select
                        v-model="item.division_id"
                        label="name"
                        placeholder="Подразделение"
                        :options="divisions"
                        :reduce="division => division.id"
                        class="select-size-md"
                    />
                    </b-form-group>    
                </b-col>
                <b-col cols="6">
                    <b-form-group>
                        <label>Введите номер</label>
                        <b-form-input v-model="item.phone" id="basicInput" placeholder="Номер телефона"/>
                    </b-form-group>
                </b-col>
                <b-col cols="6">
                   <b-form-group>
                    <label>Введите имя</label>
                        <b-form-input v-model="item.first_name" id="basicInput" placeholder="Имя"/>
                    </b-form-group>
                </b-col>
                <b-col cols="6">
                   <b-form-group>
                    <label>Введите фамилию</label>
                        <b-form-input v-model="item.last_name" id="basicInput" placeholder="Фамилия"/>
                    </b-form-group>
                </b-col>
                <b-col cols="12">
                    <b-form-group>
                        <label>Группа сотрудников</label>
                       <v-select
                        v-model="item.employee_group_id"
                        label="name"
                        @input="positionData"
                        :reduce="employee_group => employee_group.id"
                        placeholder="Группа сотрудников"
                        :options="departments"
                        class="select-size-md"
                    />
                    </b-form-group>
                </b-col>
                <template v-if="item.employee_group_id">
                    <b-col cols="12">
                        <b-form-group>
                            <label>Должности</label>
                        <v-select
                            v-model="item.position_id"
                            :reduce="position => position.id"
                            label="name"
                            :options="positions"
                            placeholder="Должности"
                            class="select-size-md"
                        />
                        </b-form-group>
                    </b-col>
                </template>
            </b-row>
            <b-row>
                <b-col cols="12" class="d-flex justify-content-between">
                    <b-button
                    :disabled="submitButtonDisabled"
                    class="mt-2 col-md-5"
                    variant="primary"
                    @click="convertData">
                        Изменить
                    </b-button>
                    <b-button class="mt-2 col-md-5" variant="secondary" @click="closeModal">
                        Отмена
                    </b-button>
                </b-col>
            </b-row>
        </b-modal>
    </div>
</template>
<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import vSelect from 'vue-select'
export default {
    props: ['id'],
    components: {
        vSelect,
        ToastificationContent
    },
    data(){
        return {
            item: {
                id: undefined,
                login: undefined,
                division_id: undefined,
                first_name: undefined,
                last_name: undefined,
                patronymic: undefined,
                phone: undefined,
                dop_phone: undefined,
                employee_group_id: undefined,
                position_id: undefined
            },
            positions: [],
            departments: [],
            divisions: [],
            submitButtonDisabled: false,
        }
    },
    computed: {

        // positionGroup(){
        //     return this.positions = this.item.employee_group
        // }
    },
    methods: {
        editEmployee(positionObj){
            this.submitButtonDisabled = true
            this.$http
                .patch(`employee/${this.id}`, positionObj)
                .then(res => {
                    this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                            title: 'Успех!',
                            icon: 'CheckIcon',
                            variant: 'success',
                            text: res.data.message,
                        },
                    })
                    this.closeModal()
                    // this.$bvModal.hide('employeeEdit')
                    this.$emit('refresh')
                })
                .catch(err=> {
                    this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                            title: 'Некорректные данные!',
                            icon: 'XIcon',
                            variant: 'danger',
                            text: err.response.data.errors,
                        },
                    })
                })
                .finally(()=>{
                    this.submitButtonDisabled = false
                })
        },
        convertData(){
            console.log(this.item);
            this.editEmployee(this.item)
            // let positionObj = JSON.parse(JSON.stringify(this.item))
            // positionObj.positions = JSON.stringify(positionObj.positions)
            // this.editPosition(positionObj)
        },
        opened(){
            this.$http
                .get('directory/divisions')
                .then(res => {
                    this.divisions = res.data
            })
            // this.$http
            //     .get('position')
            //     .then(res => {
            //         this.positions = res.data
            // })

            this.$http
                .get(`employee/${this.id}/edit`)
                .then(res => {
                    this.item = res.data
                    console.log(this.item);
                    this.$http
                        .get(`employee-group/${res.data.employee_group.id}/edit`)
                        .then(res => {
                            this.positions = res.data.positions
                        })
                //     this.$http
                //         .get(`position/${this.item.position_id}/edit`)
                //         .then(res => {
                //             this.item.position_id = res.data
                // })
            })

            this.$http
                .get('employee-group')
                .then(res => {
                    this.departments = res.data
                })
        },
        positionData(){
            console.log(this.item.employee_group.id, 'positionData');
            this.$http
                .get(`employee-group/${this.item.employee_group_id}/edit`)
                .then(res => {
                    this.positions = res.data.positions
                    this.item.position_id = ''
                    console.log(res.data.positions);
                })
        },
        closeModal(){
            this.$bvModal.hide('employeeEdit')
        }
    },
}
</script>