<template>
    <div>
        <div class="d-flex justify-content-center align-items-center" v-if="showPreloader || $store.state.refresh.fetchingNewData || $store.state.refresh.waiting_filter" style="height: 90vh;">
            <b-spinner variant="primary" label="Text Centered" />
        </div>
        <div v-else>
            <table-employe :items="items" @editForm="editForm" />
            <modal-employee @refresh="refresh"/>
            <modal-employee-edit @refresh="refresh" :id="id"/>
        </div>
            <filter-modalVue :fields="fields" @sendToParent="sendToParent"></filter-modalVue>
    </div>
</template>
<script>
import tableEmploye from '@/views/component/Table/tableEmploye.vue'
import ModalEmployee from '@/views/component/Modal/ModalEmployee/modalEmployee.vue'
import ModalEmployeeEdit from '@/views/component/Modal/ModalEmployee/modalEmployeeEdit.vue'
export default {
    components: {
        tableEmploye,
        ModalEmployee,
        ModalEmployeeEdit,
    },
    data(){
        return {
            items: [],
            showPreloader: false,
            id: undefined,
            fields: [
                { key: 'checkbox', label: '', thStyle: {width: '30px'}},
                { key: 'id', label: 'Id', sortable: true, thStyle: {width: '40px'}},
                { key: 'division', label: 'Подразделение', sortable: true, thStyle: {width: '70px'}},
                { key: 'first_name', label: 'Имя', sortable: true, thStyle: {width: '150px'}},
                { key: 'last_name', label: 'Фамилия', sortable: true, thStyle: {width: '150px'}},
                { key: 'phone', label: 'Номер телефона', sortable: true, thStyle: {width: '150px'}},
                { key: 'employee_group.name', label: 'Отдел', sortable: true, thStyle: {width: '150px'} },
                { key: 'employee_group.position.name', label: 'Должность', sortable: true, thStyle: {width: '150px'} },
                { key: 'position_id', label: 'Доступы', sortable: true, thStyle: {width: '150px'} },
                { key: 'login', label: 'Логин', sortable: true, thStyle: {width: '150px'} },
                { key: 'dop_phone', label: 'Доп. номер тел.', sortable: true, thStyle: {width: '150px'} }
            ],
        }
    },
    mounted(){
        this.$store.commit('pageData/setdataCount', null)
        // this.openFilter()
        // this.accessPage()
        this.refresh()
    },
    methods: {
        // accessPage(){
        //     let userData = JSON.parse(localStorage.getItem('userData'))
        //     let result = false
        //     userData.access.forEach(el=>{
        //         if(el.name === this.$route.name){
        //             result = true
        //         }
        //     })
        //     if (!result) {
        //         this.$router.push('error-403')
        //     }
        // },
        refresh(){
            this.showPreloader = true
            this.$http
                .get(`employee`)
                .then(res => {
                    this.items = res.data
                    this.$store.commit('pageData/setdataCount', this.items.length)
                    this.$store.commit('REFRESH_DATA', false)
                    this.showPreloader = false
                })
        },
        editForm(id){
            this.id = id
            setTimeout(this.openModal, 0);
        },
        openModal(){
            this.$bvModal.show('employeeEdit')
        },
        sendToParent(tableData){
            this.items = tableData
            this.$store.commit('pageData/setdataCount', this.items.length)
        },
       openFilter(){
            let allRoutes = this.$store.state.draggableTab.tabs
            let param = JSON.parse(localStorage.getItem(this.$route.name + 'data'))
            let arrayRoutes = []
            allRoutes.forEach(element => {
                arrayRoutes.push(element.path)
            });
            if (arrayRoutes.includes(this.$route.path) == true) {
                this.showPreloader = true
                this.$http
                    .get(`${this.$route.name}`, {params: param}).then(res => {
                        this.items = res.data
                        this.$store.commit('pageData/setdataCount', this.items.length)
                        this.showPreloader = false
                        this.$store.commit('REFRESH_DATA', false)
                    })
            }else if(arrayRoutes.includes(this.$route.path) == false){
                this.$bvModal.show(this.$route.name + 'filter')
            }
        },
    },
    computed: {
        fetchingNewData(){
            return this.$store.state.refresh.fetchingNewData
        }
    },
    watch: {
        fetchingNewData(val){
            if (val){
                this.$http
                .get(`employee`)
                .then(res =>{
                    this.items = res.data
                    this.$store.commit('pageData/setdataCount', this.items.length)
                    this.$store.commit('REFRESH_DATA', false)
                })
                }
        }    
    }
}
</script>