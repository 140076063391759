<template>
    <div>
        <b-modal :id="$route.name + 'CreateModal'" title="Регистрация сотрудника" size="sm" :no-close-on-backdrop="true" hide-footer no-enforce-focus @show="opened">
            <b-row>
                <b-col cols="6">
                    <b-form-group>
                        <label>Подразделение</label>
                       <v-select
                        v-model="item.division_id"
                        label="name"
                        placeholder="Подразделение"
                        :options="divisions"
                        :reduce="division => division.id"
                        class="select-size-md"
                    />
                    </b-form-group>    
                </b-col>
                <b-col cols="6">
                    <b-form-group>
                        <label>Введите номер</label>
                        <b-form-input v-model="item.phone" id="basicInput" placeholder="Номер телефона"/>
                    </b-form-group>
                </b-col>
                <b-col cols="6">
                   <b-form-group>
                    <label>Введите имя</label>
                        <b-form-input v-model="item.first_name" id="basicInput" placeholder="Имя"/>
                    </b-form-group>
                </b-col>
                <b-col cols="6">
                   <b-form-group>
                    <label>Введите фамилию</label>
                        <b-form-input v-model="item.last_name" id="basicInput" placeholder="Фамилия"/>
                    </b-form-group>
                </b-col>
                <b-col cols="12">
                    <b-form-group>
                        <label>Выберите группу сотрудников</label>
                       <v-select
                        v-model="employee_group"
                        label="name"
                        placeholder="Группа сотрудников"
                        :options="departments"
                        class="select-size-md"
                    />
                    </b-form-group>
                </b-col>
                <template v-if="employee_group">
                    <b-col cols="12">
                        <b-form-group>
                            <label>Выберите группу сотрудников</label>
                        <v-select
                            v-model="position"
                            label="name"
                            placeholder="Должности"
                            :options="employee_group.positions"
                            class="select-size-md"
                        />
                        </b-form-group>
                    </b-col>
                </template>
            </b-row>
            <b-row>
                <b-col cols="12" class="d-flex justify-content-between">
                    <b-button
                    :disabled="submitButtonDisabled"
                    class="mt-2 col-md-5"
                    variant="primary"
                    @click="createEmployee">
                        Добавить
                    </b-button>
                    <b-button class="mt-2 col-md-5" variant="secondary" @click="closeModal">
                        Отмена
                    </b-button>
                </b-col>
            </b-row>
        </b-modal>
    </div>
</template>
<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import vSelect from 'vue-select'
export default {
    components: {
        vSelect,
        ToastificationContent
    },
    data(){
        return {
            item: {
                position_id: undefined,
                department: {},
                employee_group_id: undefined
            },
            divisions: [],
            departments: [],
            employee_group: undefined,
            position: undefined,
            submitButtonDisabled: false,
        }
    },
    methods: {
        createEmployee(){
            this.item.employee_group_id = this.employee_group.id
            this.item.position_id = this.position.id
            this.submitButtonDisabled = true
            this.$http
                .post(`employee`, this.item)
                .then(res => {
                    this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                            title: 'Успех!',
                            icon: 'CheckIcon',
                            variant: 'success',
                            text: res.data.message,
                        },
                    })
                    this.$emit('refresh')
                    this.closeModal()
                })
                .catch(err=> {
                    this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                            title: 'Некорректные данные!',
                            icon: 'XIcon',
                            variant: 'danger',
                            text: err.response.data.errors,
                        },
                    })
                })
                .finally(()=>{
                    this.submitButtonDisabled = false
                })
        },
        opened(){
            this.$http
                .get('directory/divisions')
                .then(res => {
                    this.divisions = res.data
                })

            this.$http
                .get('employee-group')
                .then(res => {
                    this.departments = res.data
                })
            this.cleardata()
        },
        cleardata(){
            this.item= {
                position_id: undefined,
                department: {},
                employee_group_id: undefined
            }
        },
        closeModal(){
            this.$bvModal.hide(this.$route.name + 'CreateModal')
            this.clearData()
        },
    },
}
</script>